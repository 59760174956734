.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}

#resource_nav {
  margin-top: 25px;
  margin-bottom: 25px;
}

.navbar-border-bottom {
  border-radius: 0px;
  border-bottom: 1px solid lightgrey;
}
