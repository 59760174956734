.Login {
  padding: 0px 0;
  margin: 0 auto;
  margin-top: 40px;
  max-width: 420px;
}

.Login .panel {
  padding: 40px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}

.Login img {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}

.Login .LoaderButton:hover {
  background-color: #8880ff;
  border: 0px;
}

.Login .LoaderButton {
  background-color: #8880ff;
  border: 0px;
}

.Login h3 {
  margin-top: 0px;
}

input[type="email"],
input[type="password"] {
  box-shadow: none;
  outline: none;
}

input[type="email"]:focus,
input[type="password"]:focus {
  border: 2px solid #867ae0;
  box-shadow: none;
}

.Login #sign-up-wrapper {
  text-align: center;
}

.Login span {
  display: inline-block;
}

.Login a {
  color: #867ae0;
}
