.nav-tabs > li > a {
  font-size: 1.1em;
}

#datasource-detail-grid {
  padding: 0px;
}

.tab-content {
  margin-top: 15px;
  font-size: 1.1em;
}

#tab-column {
  padding-right: 3em;
}
